<template>
  <div>
    <div id="app">
      <div id="print"></div>
      <div id="web">
        <router-view></router-view>
        <CookiesBanner />
        <Loading v-if="isAppLoading"></Loading>
        <LoadingEdc ref="loadingEdc" v-if="isEdcProcessing" />

        <Dialog ref="Alert"></Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import CookiesBanner from "./components/form/CookiesBanner.vue";
import Dialog from "@/components/modal/Dialog";
import { eventBus } from "@/main";
import Loading from "./components/Loading.vue";
import LoadingEdc from "@/components/LoadingEdc.vue";

export default {
  name: "App",
  components: {
    CookiesBanner,
    Dialog,
    Loading,
    LoadingEdc,
  },
  created() {
    eventBus.$on("apiError", err => {
      const statusCode = err.response?.data.errorCode || null;
      const errorMessage = err.response?.data.errorMessage || null;

      if (statusCode == 401) {
        this.$refs.Alert.showTimeoutSession();
      } else if (statusCode == 500) {
        this.$refs.Alert.showToast(
          "danger",
          "ระบบขัดข้อง ขออภัยในความไม่สะดวก"
        );
      } else {
        this.$refs.Alert.showToast(
          "danger",
          `${err.message} \n
          ${
            errorMessage?.validationResult?.[0]?.error?.[0]?.path ||
            errorMessage
          }`
        );
      }
    });
    eventBus.$on(
      "alertToast",
      ({ message, variant = "danger", noAutoHide = false }) => {
        this.$refs.Alert.showToast(variant, message, noAutoHide);
      }
    );
    eventBus.$on(
      "alertSwal",
      ({ title, message, icon = "info", callback = () => {} }) => {
        this.$refs.Alert.showAlertInfo(title, message, icon).then(callback);
      }
    );
    eventBus.$on(
      "confirmSwal",
      ({ title, message, icon = "warning", callback = () => {} }) => {
        this.$refs.Alert.showAlertConfirm(title, message, icon).then(callback);
      }
    );
    eventBus.$on("appLoadingStatus", e => {
      this.isAppLoading = e;
    });
    eventBus.$on("edcProcessingStatus", e => {
      console.log("proceessing", e);
      this.isEdcProcessing = e;
    });
    eventBus.$on("showSpinnerToast", () => {
      this.$refs.Alert.showToastWithSpinner();
    });
    eventBus.$on("hideSpinnerToast", () => {
      this.$refs.Alert.hideToast();
    });
  },
  data() {
    return {
      isAppLoading: false,
      isEdcProcessing: false,
    };
  },
};
</script>